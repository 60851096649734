"use client"
import Container from "../Container/Container"
import Row from "../Row/Row"
import Col from "../Col/Col"
import styles from "./FooterBig.module.scss"
import Image from "next/image"
// import Link from "next/link"
import Spacer from "../Spacer/Spacer"
import RegularButton from "../RegularButton/RegularButton"
import Disclaimer from "./Disclaimer"
// import version from "@/../version.json"
import { useTranslations, useMessages } from "next-intl"
import { useSearchParams, usePathname } from "next/navigation"

export default function FooterBig() {
  const t = useTranslations()
  const content = useMessages()

  const searchParams = useSearchParams()
  const tm = searchParams.get("tm")

  const { pathname } = usePathname()

  const checkEventPage = () => {
    return pathname.startsWith("/events/") && pathname !== "/events/"
  }

  return (
    <footer className={styles.footer}>
      <Container>
        <Row>
          <Col cols="2" sm="5" md="10" lg="11">
            <a href="https://exante.eu/" target="_blank" rel="noreferrer">
              <Image
                className={styles.footer__logo}
                src="/images/logo-exante--white.svg"
                alt="EXANTE"
                width={309}
                height={72}
              />
            </a>
          </Col>
          <Spacer></Spacer>
          <Col
            cols="2"
            sm="4"
            md="8"
            lg="12"
            className={styles.footer__backlinkCol}
          >
            <a
              href="#"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              className={styles.footer__backlink}
              rel="noreferrer"
            >
              <Image
                className={styles.footer__arrow}
                src="/images/arrow-top-green.svg"
                alt="arrow"
                width={24}
                height={24}
              />
              {content.footer.toTopText}
            </a>
          </Col>
        </Row>
        <Row>
          <Col cols="24" lg="11" className={styles.footer__text}>
            <p dangerouslySetInnerHTML={{ __html: t("footer.text") }}></p>
          </Col>
          <Spacer className={styles.footer__spacer} />
          <Col cols="24" lg="12" className={styles.footer__links}>
            {tm === "0" && checkEventPage ? (
              <ul className={styles.footer__awards}>
                {content.footer.awards.map((item, index) => {
                  return (
                    <li key={index} className={styles.footer__award}>
                      <div className={styles.footer__awardTop}>
                        <img
                          src={`/images/${item.img}.png`}
                          alt={item.img.split("-").join(" ")}
                        />
                      </div>

                      <strong
                        dangerouslySetInnerHTML={{ __html: item.name }}
                      ></strong>
                    </li>
                  )
                })}
              </ul>
            ) : (
              <ul>
                {content.footer.links.map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.url}>{item.name}</a>
                    </li>
                  )
                })}
              </ul>
            )}
          </Col>
        </Row>
        <Row className={styles.footer__bottom}>
          <Col cols="24" lg="12">
            <RegularButton
              link="https://exante.eu/#open-an-account"
              target="_blank"
              rel="noreferrer"
              className={styles.footer__openbtn}
              text={content.footer.openAccountText}
            ></RegularButton>
          </Col>
        </Row>
        <Disclaimer />
        {/* <div className={styles.footer__center}>
          <a href="https://exante.eu/" target="_blank" rel="noreferrer">
            <Image
              className={styles.footer__logo}
              src="/images/logo-exante.svg"
              alt="EXANTE"
              width={309}
              height={72}
            />
          </a>
          <p dangerouslySetInnerHTML={{ __html: t.raw("footer.text") }}></p>
          <div>
            <RegularButton
              link="https://exante.eu/#open-an-account"
              newTabLink
              rel="noreferrer"
              className={styles.footer__openbtn}
              text={t("footer.openAccountText")}
            ></RegularButton>
          </div>
        </div>
        <div className={styles.footer__down}>
          <div className={styles.footer__copy}>
            &copy; 2011-{new Date().getFullYear()} EXANTE . <br />
            {t("footer.copyright")}
          </div>
          <Spacer></Spacer>
          <div className={styles.footer__topWrap}>
            <a href="#top" className={styles.footer__top}>
              <Image
                src="/images/arrow-top-green.svg?2"
                alt={t("footer.toTopText")}
                width={24}
                height={24}
              />
              <span>{t("footer.toTopText")}</span>
            </a>
          </div>
        </div>
        <div
          className={styles.footer__copy}
          aria-hidden="true"
          data-version
          style={{ textAlign: "left" }}
        >
          {version.version}
        </div> */}
      </Container>
    </footer>
  )
}
