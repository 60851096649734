import { useTranslations, useMessages } from "next-intl"
import Row from "../Row/Row"
import Col from "../Col/Col"
import Spacer from "../Spacer/Spacer"
import { useSearchParams } from "next/navigation"
import styles from "./FooterBig.module.scss"

export default function Disclaimer() {
  const t = useTranslations()
  const content = useMessages()

  const searchParams = useSearchParams()
  const tm = searchParams.get("tm")

  return (
    <Row className={styles.footer__disclaimer}>
      <Col cols="24" lg="11">
        <p
          dangerouslySetInnerHTML={{
            __html: content.footer.disclaimer.initialText,
          }}
        ></p>
      </Col>
      <Spacer />
      <Col cols="24" lg="12">
        <p
          dangerouslySetInnerHTML={{
            __html: content.footer.disclaimer.purposesText,
          }}
        ></p>
        <ul>
          {content.footer.disclaimer.purposes.map((el, index) => {
            return <li key={index}>{el}</li>
          })}
        </ul>
        <p
          dangerouslySetInnerHTML={{
            __html: content.footer.disclaimer.rightsText,
          }}
        ></p>
        <p
          dangerouslySetInnerHTML={{
            __html: content.footer.disclaimer.lastText,
          }}
        ></p>
      </Col>
      <Col
        cols="24"
        className={`${styles.footer__copyCol} 
          `}
      >
        <div className={styles.footer__copy}>
          &copy; 2011-{new Date().getFullYear()} EXANTE.{" "}
          {content.footer.copyright}
        </div>
      </Col>
    </Row>
  )
}
